
  import { defineComponent, ref } from 'vue'
  import { mapState, mapActions } from 'vuex'
  import { RedirectService } from '@/services/RedirectService'
  import { LinkActiveUserRequest } from '@/services/requests/LinkActiveUserRequest'
  import Footer from '@/assets/components/Footer.vue'

  export default defineComponent({
    name: 'ActiveUserAccount',
    components: {
      Footer
    },
    data: () => ({
      key: '' as string | null | undefined,
      isMobile: ref<boolean>(false),
      isSuccessActiveAccount: ref<boolean>(false),
      isLoadPage: ref<boolean>(true),
      visible: ref<boolean>(false),
      textVisible: ref<number>(0),
      version: ref<any>(process.env.VUE_APP_VERSION),
      activeUser: ref<LinkActiveUserRequest>({
        id: 0,
        code: ''
      })
    }),
    async mounted () {
      this.loadActiveUser()
    },
    computed: {
      ...mapState('authenticator', ['showModalState'])
    },
    methods: {
      ...mapActions('authenticator', [
        'setEmailUsuario',
        'setAdmin',
        'setLogo',
        'setUserId',
        'setAccountId'
      ]),
      showModal (number: number) {
        this.textVisible = number
        this.visible = true
      },
      handleOk (e: MouseEvent) {
        this.visible = false
      },
      async loadActiveUser () {
        this.activeUser.id = Number(this.$route.params.id)
        this.activeUser.code = this.$route.params.code.toString()

        const redirect = await RedirectService
          .getInstance()
          .getLinkToActiveUser(this.activeUser)

        if (redirect !== '') {
          setTimeout(() => {
            this.isLoadPage = false
            this.isSuccessActiveAccount = true

            setTimeout(() => {
              window.open(redirect.link, '_self')
            }, 1000)
          }, 5000)
        } else {
          this.isLoadPage = false
        }
      }
    }
  })
