
import { defineComponent, ref, h } from 'vue'
import { TokenService } from '@/services/TokenService'
import { UserService } from '@/services/UserService'
import { LoadingOutlined, CheckCircleFilled } from '@ant-design/icons-vue'
import Footer from '@/assets/components/Footer.vue'

export default defineComponent({
  name: 'ValidateUser',
  components: {
    CheckCircleFilled,
    Footer
  },
  data: () => ({
    key: '' as string | null | undefined,
    show: true as boolean,
    userService: null as UserService | null,
    success: false as boolean,
    error: false as boolean,
    indicator: h(LoadingOutlined, {
      style: {
        fontSize: '120px',
        color: '#f5f5f5'
      },
      spin: true
    }),
    version: ref<any>(process.env.VUE_APP_VERSION)
  }),

  async mounted () {
    this.userService = UserService.getInstance()
    this.key = this.$route.query.key?.toString()

    TokenService.setToken(this.key!)
    await this.verifyUser()
  },

  computed: {
    title () {
      if (this.success) {
        return 'Usuário verificado'
      }

      if (this.error) {
        return 'Link expirado'
      }

      return ''
    },

    subTitle () {
      if (this.success) {
        return 'O email foi verificado com sucesso!'
      }

      if (this.error) {
        return 'Entre em contato com o nosso suporte!'
      }

      return ''
    }
  },

  methods: {
    goToLogin () {
      this.$router.replace('/login')
    },
    async verifyUser () {
      this.show = true
      try {
        await this.userService?.verify()
        this.success = true
        this.error = false
      } catch (e) {
        this.success = false
        this.error = true
      }
      this.show = false
      TokenService.clear()
    }
  }
})
